import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import MenuBar from '../components/MenuBar/MenuBar.js';
import HeroLayout from '../components/HeroLayout/HeroLayout.js';
import SectionLayout from '../components/SectionLayout/SectionLayout.js';
import FloatingTextLayout from '../components/FloatingTextLayout/FloatingTextLayout.js';

// styles
// markup
function IndexPage() {
  return (
    <main>
      <MenuBar />
      <HeroLayout title="Record your music in the beautiful nature" />
      <SectionLayout
        text="We rent a cozy cabin and work there together until we have a record we can all be proud of. This allows you to find the perfect location for your record."
        imageSrc="guitars"
        direction="forward"
      />
      <SectionLayout
        text="In the woods, the mountains, or by the sea. Which ever inspires your music. We can travel with a portable, yet high-quality recording studio anywhere."
        imageSrc="river"
        direction="reverse"
      />
      <SectionLayout
        text="Submit your band if you cannot afford studio time and apply for a free session."
        imageSrc="drums"
        direction="forward"
      />
      <SectionLayout
        text="Flat rate per song or per day. Fully recorded, mixed, and mastered."
        imageSrc="desk"
        direction="reverse"
      />
      <div>
        <FloatingTextLayout title="Great gear &amp; great sounds." />
        <StaticImage
          src="../images/mountains.jpeg"
          alt="A cabin with a view"
          placeholder="tracedSVG"
          layout="fullWidth"
          breakpoints={['768', '1500', '1920']}
        />
      </div>
      <div>
        <FloatingTextLayout title="Based in Oslo. Record anywhere." />
        <StaticImage
          src="../images/fireplace.jpeg"
          alt="A cabin with a view"
          placeholder="tracedSVG"
          layout="fullWidth"
          breakpoints={['768', '1500', '1920']}
        />
        <StaticImage
          src="../images/river.jpeg"
          alt="A cabin with a view"
          placeholder="tracedSVG"
          layout="fullWidth"
          breakpoints={['768', '1500', '1920']}
        />
        <StaticImage
          src="../images/cabin2.jpeg"
          alt="A cabin with a view"
          placeholder="tracedSVG"
          layout="fullWidth"
          breakpoints={['768', '1500', '1920']}
        />
      </div>
    </main>
  );
}

export default IndexPage;
