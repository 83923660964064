import * as React from 'react';
import { string } from 'prop-types';

import { StaticImage } from 'gatsby-plugin-image';

import './HeroLayout.scss';

function HeroLayout({ title }) {
  return (
    <div className="HeroLayout-block">
      <StaticImage
        src="../../images/cabin.jpeg"
        alt="A cabin with a view"
        placeholder="tracedSVG"
        layout="fullWidth"
        aspectRatio={4 / 3}
        breakpoints={['768', '1500', '1920']}
      />
      <div className="HeroLayout-title-container">
        <h1 className="HeroLayout-title">{title}</h1>
      </div>
    </div>
  );
}

HeroLayout.propTypes = {
  title: string.isRequired,
};

export default HeroLayout;
