import * as React from 'react';
import Button from '../Button/Button';
import './MenuBar.scss';

function MenuBar() {
  return (
    <div className="MenuBar-block">
      <div className="MenuBar-content">
        <div className="MenuBar-text">
          RECORDING STUDIO <span className="MenuBar-text--small">CABIN</span>
        </div>
        <a
          role="button"
          href="mailto:nick+recording@innovatenow.no?subject=We should make a record!"
        >
          <Button title="Reserve a Session" />
        </a>
      </div>
    </div>
  );
}

export default MenuBar;
