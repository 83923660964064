import * as React from 'react';
import { string } from 'prop-types';

import './Button.scss';

function Button({ title }) {
  return (
    <div role="button" className="Button">
      {title}
    </div>
  );
}

Button.propTypes = {
  title: string.isRequired,
};

export default Button;
