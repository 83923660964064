import * as React from 'react';
import { string } from 'prop-types';
import Button from '../Button/Button';

import './FloatingTextLayout.scss';

function FloatingTextLayout({ title }) {
  return (
    <div className="FloatingTextLayout-block">
      <div className="FloatingTextLayout-title-container">
        <h3 className="FloatingTextLayout-title">{title}</h3>
        <div className="FloatingTextLayout-button-container">
          <a
            role="button"
            href="mailto:nick+recording@innovatenow.no?subject=We should make a record!"
          >
            <Button title="Book Your Session" />
          </a>
        </div>
      </div>
    </div>
  );
}

FloatingTextLayout.propTypes = {
  title: string.isRequired,
};

export default FloatingTextLayout;
