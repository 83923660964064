/* eslint-disable prettier/prettier */
import * as React from 'react';
import { string } from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

import './SectionLayout.scss';

function SectionLayout({ text, imageSrc, direction }) {
  return (
    <div
      className={`SectionLayout-block ${direction === 'forward'
        ? 'SectionLayout-block--forward'
        : 'SectionLayout-block--reverse'
        }`}
    >
      <div className="SectionLayout-images-container">
        {imageSrc === 'guitars' && (
          <StaticImage
            src="../../images/guitars.jpeg"
            alt="Recording Image"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            breakpoints={['768', '1500', '1920']}
          />
        )}
        {imageSrc === 'fireplace' && (
          <StaticImage
            src="../../images/fireplace.jpeg"
            alt="Recording Image"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            breakpoints={['768', '1500', '1920']}
          />
        )}
        {imageSrc === 'river' && (
          <StaticImage
            src="../../images/guitarplayer.jpeg"
            alt="Recording Image"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            breakpoints={['768', '1500', '1920']}
          />
        )}
        {imageSrc === 'drums' && (
          <StaticImage
            src="../../images/drumplayer.jpeg"
            alt="Recording Image"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            breakpoints={['768', '1500', '1920']}
          />
        )}
        {imageSrc === 'desk' && (
          <StaticImage
            src="../../images/desk.jpeg"
            alt="Recording Image"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={1}
            breakpoints={['768', '1500', '1920']}
          />
        )}
      </div>

      <div className="SectionLayout-text-container">
        <h2 className="SectionLayout-text">{text}</h2>
      </div>
    </div>
  );
}

SectionLayout.propTypes = {
  text: string.isRequired,
  imageSrc: string.isRequired,
  direction: string.isRequired,
};

export default SectionLayout;
